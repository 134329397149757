.name-box {
  position: relative;
  margin: 0;
  padding: 0;
  // background-color: aqua;
  top: 465px;
  left: 60px;
  font-size: 36px;
  letter-spacing: 2px;
  // transition: 0.3s;
  text-align: left;
  max-width: 300px;
  opacity: 0;
  text-shadow: 1px 1px 2px rgb(8, 8, 8), 0 0 1em rgb(0, 0, 0),
    0 0 0.2em rgb(0, 0, 0);
}

.name-age {
  font-size: 22px;
}
// .foto-boxes {
//   // margin: 100px;
//   // background-color: aqua;
//   margin: ;
// }

.box-photo {
  background-size: contain;
  height: 540px;
  width: 360px;
  border-radius: 2px;
  background-size: cover;

  // padding: 20px;
  filter: grayscale(1);
  transition: 0.3s;
}
.box-margin {
  margin: 20px;
}
@media (min-width: 1000px) {
  .box-photo:hover {
    filter: grayscale(0);
    .name-box {
      top: 390px;
      line-height: 50px;
      transition: 0.3s;
      opacity: 1;
    }
  }
}

.img-name-holder {
  display: flex;
  flex-wrap: wrap;
  display: inline;
}

.container-actor {
  width: 100px;
}

@media (max-width: 999px) {
  .name-box {
    top: 390px;
    line-height: 50px;
    opacity: 1;
  }
  .box-photo {
    filter: grayscale(0);
  }
}
@media (max-width: 499px) {
  .box-photo {
    height: 470px;
    width: 300px;
  }
  .name-box {
    // background-color: aqua;
    top: 330px;
    max-width: 295px;
    left: 15px;
    font-size: 20px;
  }
  .name-age {
    font-size: 15px;
  }
}
