.end {
  margin: 50px 0 800px 0;
}
.contants-main {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
}
.contact-img {
  height: 500px;
  position: relative;
  right: 6px;
}
.contants {
  letter-spacing: 2px;
  position: relative;
  left: 50px;
  top: 15px;
  text-align: left;
  margin: 10px 0 5px 0;
  transition: 0.3s;
}
.div-contants {
  position: relative;
  font-size: 25px;
  display: inline-block;
}

.div-name {
  margin-bottom: 30px;
  font-size: 42px;
}
.div-tg {
  width: 209.5px;
  border-bottom: 1px solid #fff;
  transition: 0.3s;
}

.div-tg:hover {
  width: 209.5px;
  transition: 0.3s;
  color: rgb(196 55 33);
  border-bottom: 1px solid rgb(196 55 33);
}

@media (max-width: 850px) {
  .contants {
    left: 7px;
    transition: 0.3s;
  }
}
@media (max-width: 800px) {
  .contants-main {
    display: block;
  }
}
@media (max-width: 499px) {
  .contants {
    letter-spacing: 2px;
    font-size: 22px;
  }
  .div-tg {
    width: 190px;
    border-bottom: 1px solid #fff;
    transition: 0.3s;
  }
}