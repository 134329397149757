.main-nav {
  padding-bottom: 30px;
}

.li-nav {
  display: inline;
  color: #7e7a79;
  font-size: 34px;
  margin: 0 70px;
  letter-spacing: 3.5px;
  transition: 0.3s;
}

.li-nav:hover {
  transition: 0.3s;
  color: rgb(250, 250, 250);
}

@media (max-width: 1200px) {
  .li-nav {
    margin: 0 30px;
  }
}

@media (max-width: 860px) {
  .li-nav {
    margin: 0 10px;
  }
}

@media (max-width: 740px) {
  .li-nav {
    display: block;
    margin: 10px 5px;
  }
  .main-nav {
    padding-bottom: 0;
  }
}
