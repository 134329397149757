.main-container {
  position: relative;
  margin: 0 auto 0 auto;
  max-width: 1000px;
}
.first-container {
  display: flex;
  margin-bottom: 50px;
}

.main-photo {
  position: relative;
  display: flex;
  margin: 40px 170px 40px 100px;
  height: 537px;
  transition: 0.3s;
}

.stats-andName {
  position: relative;
  margin: 40px 0 0 0;
  text-align: left;
  font-size: 22px;
  letter-spacing: 2px;
}

.actor-name {
  text-align: center;
  margin-bottom: 50px;
  font-size: 50px;
  max-width: 500px;
}

.stats-params {
  margin-bottom: 50px;
}
.player {
  margin: 0 0 0 100px;
  transition: 0.3s;
}

.info-div {
  position: relative;
}

.drop-btn {
  font-family: "Alumni Sans Pinstripe", sans-serif;
  font-size: 45px;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 14px;
  padding: 2px 1px 2px 8px;
  box-sizing: border-box;
  margin-bottom: 5px;
  transition: 0.3s;
}

.drop-btn:hover {
  transition: 0.3s;
  color: rgb(250, 250, 250);
}
.arrow {
  position: relative;
  top: 4px;
}
.stats-item {
  word-spacing: 2px;
}
.drop-contet {
  vertical-align: middle;
  position: relative;
  text-align: left;
  line-height: 30px;
  font-size: 25px;
  letter-spacing: 2px;
  line-height: 50px;

  &.hide {
    opacity: 0;
    max-height: 0px;
    transition: 0.5s;
  }

  &.show {
    opacity: 1;
    max-height: 100000px;
    transition: 0.5s;
  }
}
.drop-margin {
  margin-bottom: 30px;
}
.drop-end {
  margin-bottom: 50px;
}

.main-img-container {
  max-width: 1500px;
  margin: 0 auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.more-img {
  margin: 10px;
  height: 320px;
  border-radius: 2px;
}

.download-btn {
  font-family: "Alumni Sans Pinstripe", sans-serif;
  margin-left: 790px;
  padding: 10px;
  font-size: 35px;
  font-weight: 1000;
  letter-spacing: 1px;
  transition: 0.3s;
}

.download-btn:hover {
  transition: 0.3s;
  color: white;
}

@media (max-width: 950px) {
  .main-photo {
    margin: 40px 50px 40px 50px;
    transition: 0.3s;
  }
  .download-btn {
    margin-left: 0px;
  }
  .player {
    margin: 0 0 0 50px;
    transition: 0.3s;
  }
}
@media (max-width: 750px) {
  .first-container {
    display: block;

    justify-content: center;
  }

  .main-photo {
    margin: 0 auto 25px auto;
    display: block;
  }
  .player {
    margin: 0 auto;
    display: block;
  }
  .stats-andName {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .actor-name {
    text-align: center;
    margin: 25px auto;
  }
  .h2-mini {
    text-align: center;
  }
  .stats-params {
    margin: 0 auto 50px 10px;
  }
  .education {
    margin: 0 auto 50px 10px;
  }
}
@media (max-width: 499px) {
  .actor-name {
    font-size: 40px;
  }
  .drop-btn {
    font-size: 30px;
  } 
  .drop-contet {
    left: 5px;
    font-size: 16px;
  }
  .more-img {
    margin: 5px;
    height: 185px;
  }
}
