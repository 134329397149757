.header {
  padding-bottom: 45px;
  text-align: center;
}

.head-name {
  margin: 2px 0 15px 0;
}

.box {
  display: inline-block;
  position: relative;
}
.logo {
  height: 110px;
  transition: 0.3s;
}

.info {
  position: absolute;
  // display: flex;
  padding-top: 10px;
  color: #7e7a79;
  right: 60px;
  top: 20px;
  font-size: 24px;
  letter-spacing: 1.5px;
  line-height: 12px;
}

@media (max-width: 1155px) {
  .logo {
    height: 90px;
  }
  .info {
    font-size: 20px;
  }
}
@media (max-width: 950px) {
  .info {
    position: static;
    display: block;
  }
}
@media (max-width: 550px) {
  .logo {
    height: 80px;
  }
}
@media (max-width: 499px) {
  .logo {
    height: 65px;
  }
}
